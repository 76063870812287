import { StaticQuery, graphql } from 'gatsby'
import { breakpoints, colors, fontSizes } from '../variables'

import Icon from './icon'
import Img from 'gatsby-image'
import Line from './line'
import React from 'react'
import Subtitle from './subtitle'
import Wrapper from './wrapper'
import { css } from '@emotion/react'

const boxShadow = `0 0 15px ${colors.shadow}`

const Arrow = ({ top }) => (
  <div
    css={{
      background: 'white',
      boxShadow: top ? 'none' : boxShadow,
      height: 16,
      left: -4,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%) rotate(45deg)',
      width: 16,
      zIndex: top ? 'auto' : -1,
      [`@media(min-width: ${breakpoints.tablet}px)`]: {
        left: -8,
      },
    }}
  />
)

const ResponsiveIcon = (props) => (
  <Icon
    className={{
      [`@media(max-width: ${breakpoints.tablet - 1}px)`]: {
        height: 30,
        width: 30,
        svg: {
          height: 14,
          width: 14,
        },
      },
    }}
    {...props}
  />
)

const Location = ({ children, icon, transport }) => (
  <div
    css={{
      background: 'white',
      marginBottom: 40,
      position: 'relative',
      '&:last-child': {
        marginBottom: 0,
      },
    }}
  >
    <div
      data-location
      data-transport={transport}
      css={{
        alignItems: 'center',
        display: 'flex',
        height: 30,
        left: -42,
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 30,
        [`@media(min-width: 400px)`]: {
          left: -47,
        },
        [`@media(min-width: ${breakpoints.tablet}px)`]: {
          height: 60,
          left: -112,
          width: 60,
        },
      }}
    >
      {icon ? (
        <ResponsiveIcon name={icon} color="blue" />
      ) : (
        <div
          css={{
            background: colors.blue75,
            borderRadius: '100%',
            height: 20,
            width: 20,
          }}
        />
      )}
    </div>
    <Arrow />
    <Arrow top={true} />
    <div
      css={{
        borderRadius: 5,
        boxShadow,
        padding: 20,
        overflow: 'hidden',
        [`@media(min-width: ${breakpoints.tablet}px)`]: {
          padding: '25px 25px 25px 40px',
        },
        [`@media(min-width: 1150px)`]: {
          padding: '40px 40px 40px 50px',
        },
      }}
    >
      {children}
    </div>
  </div>
)

const Excursion = ({
  supertitle,
  title,
  image,
  children,
  smallContent = false,
  imageAlign,
}) => (
  <div
    css={{
      marginBottom: 50,
      ':last-child': {
        marginBottom: 0,
      },
      [`@media(min-width: ${breakpoints.tablet}px)`]: {
        marginBottom: 80,
      },
      [`@media(min-width: ${breakpoints.laptop}px)`]: {
        alignItems: 'center',
        display: 'flex',
      },
      [`@media(min-width: ${breakpoints.full}px)`]: {
        alignItems: 'flex-start',
      },
    }}
  >
    {image && (
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          margin: -20,
          marginBottom: '1.5rem',
          maxHeight: 300,
          overflow: 'hidden',
          [`@media(min-width: ${breakpoints.tablet}px)`]: {
            margin: '-25px -25px 2rem -40px',
          },
          [`@media(min-width: ${breakpoints.laptop}px)`]: {
            borderRadius: 3,
            margin: 0,
            maxHeight: 'none',
            minWidth: 360,
            order: imageAlign === 'left' ? 0 : 1,
          },
          [`@media(min-width: 1150px)`]: {
            minWidth: smallContent ? 420 : 480,
          },
        }}
      >
        <Img fluid={image.childImageSharp.fluid} style={{ width: '100%' }} />
      </div>
    )}
    <div
      css={{
        [`@media(min-width: ${breakpoints.laptop}px)`]:
          imageAlign === 'left'
            ? {
                marginLeft: smallContent ? 60 : 80,
              }
            : {
                marginRight: smallContent ? 60 : 80,
              },
      }}
    >
      {supertitle && (
        <Subtitle
          className={{
            marginBottom: 5,
          }}
          small
        >
          {supertitle}
        </Subtitle>
      )}
      {smallContent ? (
        <h4 css={{ marginBottom: '.75rem' }}>{title}</h4>
      ) : (
        <h3 css={{ marginBottom: '1rem' }}>{title}</h3>
      )}
      <Line small={true} />
      <div
        css={{
          fontSize: fontSizes.small,
          '> :last-child': {
            marginBottom: 0,
          },
          [`@media(min-width: ${breakpoints.tablet}px)`]: {
            fontSize: smallContent ? fontSizes.small : 'inherit',
          },
        }}
      >
        {children}
      </div>
    </div>
  </div>
)

export default class Itinerary extends React.Component {
  locationWrapper = React.createRef()
  state = {
    active: null,
    near: null,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (!this.locationWrapper.current) {
      return
    }

    requestAnimationFrame(() => {
      const locations =
        this.locationWrapper.current.querySelectorAll('[data-location]')
      const active = [].reduce.call(
        locations,
        (active, location, index) => {
          if (window.innerHeight / 2 > location.getBoundingClientRect().top) {
            return index === locations.length - 1 ? false : location
          }

          return active
        },
        false
      )
      const plus = [].reduce.call(
        locations,
        (near, location) => {
          return window.innerHeight / 2 >
            location.getBoundingClientRect().top - 20
            ? location
            : near
        },
        false
      )
      const min = [].reduce.call(
        locations,
        (near, location) => {
          return window.innerHeight / 2 >
            location.getBoundingClientRect().top + 20
            ? location
            : near
        },
        false
      )

      this.setState({
        active,
        near: plus !== active || min !== active,
      })
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            relaxen: file(relativePath: { eq: "relaxen.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 320) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            tokyo: file(relativePath: { eq: "tokyo.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 320) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            amsterdam: file(relativePath: { eq: "amsterdam.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            tonga: file(relativePath: { eq: "tonga.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            vavau: file(relativePath: { eq: "vavau.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            fiji_1: file(relativePath: { eq: "fiji_1.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            fiji_2: file(relativePath: { eq: "fiji_2.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            nukualofa: file(relativePath: { eq: "nukualofa.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 420, maxHeight: 280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            whale: file(relativePath: { eq: "whale.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 420, maxHeight: 280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hapaai: file(relativePath: { eq: "hapaai.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 420, maxHeight: 280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            new_zealand: file(relativePath: { eq: "new-zealand.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={(data) => (
          <div
            css={css`
              margin-bottom: 60px;

              @media (min-width: ${breakpoints.tablet}px) {
                margin-bottom: 120px;
              }

              @media (min-width: ${breakpoints.laptop}px) {
                margin-top: -60px;
              }

              @media (min-width: 1600px) {
                margin-top: -120px;
              }
            `}
            id="dag-tot-dag"
          >
            <Wrapper>
              <h2 css={{ marginBottom: -5 }}>Van dag tot dag</h2>
              <Subtitle>Je reis in vogelvlucht</Subtitle>
              <Line />

              <div
                css={{
                  display: 'flex',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    flexShrink: 0,
                  }}
                >
                  <div
                    css={{
                      display: this.state.active ? 'block' : 'none',
                      marginLeft: -3,
                      position: 'fixed',
                      top: '50%',
                      transform: this.state.near ? 'scale(.3)' : 'none',
                      transition: 'transform .3s cubic-bezier(0, 0, .5, 1)',
                      [`@media(min-width: 400px)`]: {
                        marginLeft: 5,
                      },
                      [`@media(min-width: ${breakpoints.tablet}px)`]: {
                        marginLeft: 0,
                      },
                      svg: {
                        opacity: this.state.near ? 0 : 1,
                        transition: 'opacity .3s cubic-bezier(0, 0, .5, 1)',
                      },
                    }}
                  >
                    <ResponsiveIcon
                      name={
                        this.state.active
                          ? this.state.active.dataset.transport
                          : null
                      }
                    />
                  </div>
                  <div
                    css={{
                      background: `linear-gradient(to bottom, ${colors.blue5} 50%, transparent 50%)`,
                      backgroundSize: '100% 20px',
                      marginLeft: 10,
                      marginRight: 25,
                      width: 4,
                      [`@media(min-width: 400px)`]: {
                        marginLeft: 18,
                        marginRight: 30,
                      },
                      [`@media(min-width: ${breakpoints.tablet}px)`]: {
                        marginLeft: 28,
                        marginRight: 80,
                      },
                    }}
                  />
                </div>
                <div
                  css={{
                    flexGrow: 1,
                    flexBasis: '0px',
                  }}
                  ref={this.locationWrapper}
                >
                  <Location icon="takeoff" transport="airplane-down">
                    <Excursion
                      title="Van Amsterdam naar Fiji"
                      image={data.amsterdam}
                    >
                      <p>
                        Je vertrekt vanaf Amsterdam en vliegt rechtstreeks naar
                        Tokyo in 11 uur. Dit is een nachtvlucht met een premium
                        airline waarbij een warme maaltijd én ontbijt worden
                        geserveerd. Je hebt voldoende tijd om ongeveer 8 uur te
                        slapen. Door het tijdsverschil is het voor je lichaam
                        bij aankomst nog vroeg. Daarom kun je uitrusten in een
                        door ons geregelde prive slaapkamer op het vliegveld en
                        daarna genieten van een spa en massage. Na het avondeten
                        vlieg je in 8 uur door naar Fiji.
                      </p>
                    </Excursion>
                  </Location>

                  <Location transport="boat">
                    <Excursion
                      supertitle=""
                      title="Relaxen in comfort"
                      image={data.relaxen}
                      imageAlign="left"
                    >
                      <p>
                        Na de lange reis kom je in de ochtend aan in Fiji. Je
                        wordt opgehaald op het vliegveld en naar een luxe resort
                        gebracht. Het resort is gelegen nabij het strand en
                        heeft een zwembad en comfortabele kamers. Je kunt hier
                        een paar dagen genieten van lokale en internationale
                        cuisine, luxe en comfort en gastvrijheid in Fiji.
                      </p>
                    </Excursion>
                  </Location>

                  <Location transport="boat">
                    <Excursion
                      supertitle=""
                      title="Op weg naar Vava’U Tonga"
                      image={data.tonga}
                    >
                      <p>
                        Met een Franse ATR 72 turboprop, vlieg je met een
                        ochtendvlucht in twee uur naar Nuku’alofa Tonga en dan
                        met een binnenlands vliegtuig naar VaVa U Tonga, een van
                        de minst bezochte landen in de Stille Zuidzee. Tonga
                        werd door Captain Cook “de friendly islands” genoemd en
                        je zult merken dat de mensen hier enorm vriendelijke en
                        verwelkomend zijn.
                      </p>
                    </Excursion>

                    <Excursion
                      supertitle=""
                      title="Tonga: Vava’u archipel ontdekken"
                      smallContent={true}
                      image={data.vavau}
                      imageAlign="left"
                    >
                      <p>
                        Vava’u Tonga is opgebouwd uit honderden kleine en
                        grotere eilanden. Je verblijft hier op bounty eiland
                        terwijl je de lokale cultuur leert kennen. Je leeft
                        onder de palmbomen. Je kunt snorkelen, zwemmen,
                        (kreeft)bbq-en op het strand op gewoon niets doen in je
                        hangmat. Het is paradijselijk. Het hoogtepunt is de
                        walvis expeditie waarbij je de walvissen ontmoet en met
                        ze snorkelt in het water. Je bezoekt ook de coral
                        gardens en een schitterende grot waar je in kunt
                        snorkelen.
                      </p>
                    </Excursion>

                    <Excursion
                      supertitle=""
                      title="Tonga: Nuku’alofa ontdekken"
                      smallContent={true}
                      image={data.nukualofa}
                    >
                      <p>
                        Je vliegt met een binnenlandse vlucht naar Tongatapu
                        waar je verblijft in de hoofdstad van Tonga: Nuku’alofa.
                        Mocht je nog een keer met walvissen willen zwemmen, dan
                        kan dat hier ook. Verder regelen we een dagexcursie voor
                        je op dit eiland en kun je deze bijzondere plek en de
                        hoofdstad van deze pacifische eilandstaat ervaren.
                      </p>
                    </Excursion>
                  </Location>

                  <Location transport="boat">
                    <Excursion
                      supertitle=""
                      title="Fiji: Cast Away islands (I)"
                      image={data.fiji_1}
                    >
                      <p>
                        Je vliegt naar Fiji waar je vlakbij de haven verblijft.
                        De volgende ochtend ga je vroeg op weg naar de afgelegen
                        eilanden van Fiji. Met een high speed boot reis je langs
                        tientallen adembenemende eilanden. Op het dek zit je
                        buiten en kun je genieten van deze prachtige bootreis.
                        Eenmaal aangekomen ben je waarlijk op een cast away
                        eiland. Je kunt hier snorkelen, kajakken, lokale
                        tradities leren en genieten van heerlijk eten. De
                        omgeving is waanzinnig mooi.
                      </p>
                    </Excursion>
                  </Location>

                  <Location transport="airplane-down">
                    <Excursion
                      supertitle=""
                      title="Fiji: Cast Away islands (II)"
                      image={data.fiji_2}
                    >
                      <p>
                        Je gaat nog verder weg en vertrekt na het ontbijt en
                        komt na een uur aan op je tweede eiland. Je verblijft
                        ook hier op een klein lokaal eiland resort waar je
                        heerlijk kunt genieten in een hangmat en de Pacifische
                        lifestyle kunt ervaren. Je kunt er ook hier zwemmen maar
                        ook Eco hiken, een cursus “weaving” doen, leren
                        cocosnoot “husken”, kayaking, koraal snorkelen (van het
                        strand) kunt doen een nog veel meer. We hebben ook 2
                        excursies gereserveerd om te gaan zwemmen met de
                        manta’s.
                      </p>
                    </Excursion>
                  </Location>

                  <Location transport="airplane-down">
                    <Excursion
                      supertitle=""
                      title="Weekendje Tokyo (optioneel)"
                      image={data.tokyo}
                    >
                      <p>
                        Als je terugkeert, ga je weer met de high speed boot
                        terug. Je verblijf de nacht voor je terugvlucht opnieuw
                        in het luxe resort en vliegt de volgende dag terug naar
                        Tokyo. Als je een paar dagen Japan wilt ervaren, kun je
                        3 nachten van je reis inzetten voor een bezoek aan
                        Tokyo.
                      </p>
                    </Excursion>
                  </Location>

                  <Location icon="landing">
                    <Excursion title="Terugvlucht">
                      <p>
                        Het einde van je onvergetelijke reis. Je reist via Tokyo
                        naar huis met 2 vluchten van 10 en 11 uur met een pauze
                        er tussen om de benen te strekken en een hapje te eten
                        op het vliegveld in Tokyo.
                      </p>
                    </Excursion>
                  </Location>
                </div>
              </div>
            </Wrapper>
          </div>
        )}
      />
    )
  }
}
